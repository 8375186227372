.input-select {
  width: 100%;
  height: 30px;
  background-color: #e4e4e4;
  border-radius: 6px;
  position: relative;

  svg {
    max-width: 100%;
    width: auto;
    height: 5px;
    display: inline-block;
  }

  .select {
    color: #333;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 6px;
    cursor: pointer;
    background-image: url('./../icons/caret-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 6px;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;

    .active-option * {
      max-width: 100%;
    }

    .list {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: 100%;
      border: 1px solid #ddd;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      display: none;
    }
  }

  &.open .list {
    display: block;
  }

  .option {
    width: 100%;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    clear: both;
    white-space: nowrap;

    &:hover {
      background-color: #eee;
    }

  }
}
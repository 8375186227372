.floating-menu {
  position: absolute;
  left: 100px;
  top: 100px;
  background-color: #fff;
  border: 1px solid #cacaca;
  box-shadow: 0 1px 4px 0 rgba(50, 50, 50, 0.2);

  div {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #dbdbdb;
  }

  div:hover {
    background-color: #eee;
    color: #000;
  }
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgba(85, 85, 85, 1);
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;

  img,
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    pointer-events: none;
  }
}

button:focus {
  outline:0;
}
